// TimeWeekSummaryDay.tsx

import styles from './TimeWeekSummaryDay.module.scss';

type TimeWeekSummaryDayProps = {
  weekDay: string;
  timeOff: number;
  sickLeave: number;
  work: number;
  holiday: number;
  overtime: number;
  total: number;
  notApplicable?: boolean; // New optional prop
};

function TimeWeekSummaryDay({
  weekDay,
  timeOff,
  sickLeave,
  work,
  holiday,
  overtime,
  total,
  notApplicable = false, // Default to false
}: TimeWeekSummaryDayProps) {
  return (
    <div className={styles.timeWeekSummaryDay}>
      <div className={styles.timeWeekSummaryDayRow}>
        <div className={styles.timeWeekSummaryDayRowLabel}>{weekDay}</div>
        <div className={styles.timeWeekSummaryDayRowValue}>
          {total} {`time${total > 1 ? 'r' : ''}`}
        </div>
      </div>

      {notApplicable ? (
        <div className={styles.timeWeekSummaryDayRow}>
          <div className={styles.timeWeekSummaryDayRowLabel}>
            Ikke en arbejdsdag
          </div>
          <div className={styles.timeWeekSummaryDayRowValue}>–</div>
        </div>
      ) : (
        <>
          {timeOff > 0 && (
            <div className={styles.timeWeekSummaryDayRow}>
              <div className={styles.timeWeekSummaryDayRowLabel}>Fri</div>
              <div className={styles.timeWeekSummaryDayRowValue}>
                {timeOff} {`time${timeOff > 1 ? 'r' : ''}`}
              </div>
            </div>
          )}
          {sickLeave > 0 && (
            <div className={styles.timeWeekSummaryDayRow}>
              <div className={styles.timeWeekSummaryDayRowLabel}>Syg</div>
              <div className={styles.timeWeekSummaryDayRowValue}>
                {sickLeave} {`time${sickLeave > 1 ? 'r' : ''}`}
              </div>
            </div>
          )}
          {work > 0 && (
            <div className={styles.timeWeekSummaryDayRow}>
              <div className={styles.timeWeekSummaryDayRowLabel}>
                Arbejdstid
              </div>
              <div className={styles.timeWeekSummaryDayRowValue}>
                {work} {`time${work > 1 ? 'r' : ''}`}
              </div>
            </div>
          )}
          {holiday > 0 && (
            <div className={styles.timeWeekSummaryDayRow}>
              <div className={styles.timeWeekSummaryDayRowLabel}>Ferie</div>
              <div className={styles.timeWeekSummaryDayRowValue}>
                {holiday} {`time${holiday > 1 ? 'r' : ''}`}
              </div>
            </div>
          )}
          {overtime > 0 && (
            <div className={styles.timeWeekSummaryDayRow}>
              <div className={styles.timeWeekSummaryDayRowLabel}>
                Arbejde u.f. normtid
              </div>
              <div className={styles.timeWeekSummaryDayRowValue}>
                {overtime} {`time${overtime > 1 ? 'r' : ''}`}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default TimeWeekSummaryDay;
