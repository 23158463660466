import { createContext, useState } from 'react';
import TimeModal from '../components/time/modal/TimeModal';
import { TimeLogEntryType, TimeLogInterface } from '../types/Time';
import { Timestamp } from 'firebase/firestore';
import { AnimatePresence } from 'framer-motion';

type TimeModalContextType = {
  timeLog: TimeLogInterface | null;
  preValues: TimeLogEntryType;
  isOpen: boolean;
  open: (
    timeLog?: TimeLogInterface,
    preValues?: TimeModalContextType['preValues']
  ) => void;
  close: () => void;
};

export const TimeModalContext = createContext<TimeModalContextType>({
  isOpen: false,
  timeLog: null,
  preValues: {
    id: '',
    note: '',
    endTime: Timestamp.now(),
    startTime: Timestamp.now(),
    type: undefined,
    timeOffType: undefined,
    hours: 0,
  },
  open: () => {},
  close: () => {},
});

export const TimeModalProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [timeLog, setTimeLog] = useState<TimeLogInterface | null>(null);
  const [values, setValues] = useState<TimeLogEntryType>({
    id: '',
    note: '',
    endTime: Timestamp.now(),
    startTime: Timestamp.now(),
    type: undefined,
    timeOffType: undefined,
    hours: 0,
  });

  const open = (
    timeLog?: TimeLogInterface,
    preValues?: TimeModalContextType['preValues']
  ) => {
    if (timeLog) {
      setTimeLog(timeLog);
    }

    if (preValues) {
      setValues(preValues);
    }
    setIsOpen(true);
  };

  const close = () => {
    setTimeLog(null);
    setValues({
      id: '',
      note: '',
      endTime: Timestamp.now(),
      startTime: Timestamp.now(),
      type: undefined,
      timeOffType: undefined,
      hours: 0,
    });
    setIsOpen(false);
  };

  return (
    <TimeModalContext.Provider
      value={{ timeLog, preValues: values, isOpen, open, close }}
    >
      {children}
      <AnimatePresence>
        <TimeModal />
      </AnimatePresence>
    </TimeModalContext.Provider>
  );
};
