import styles from './Infobox.module.scss';
import { motion } from 'framer-motion';
import Button from '../button/Button';
import { useState } from 'react';

function Infobox({
  label,
  content,
}: {
  label: string;
  content: React.ReactNode;
}) {
  const [show, setShow] = useState(false);

  return (
    <>
      <motion.div
        className={styles.InfoboxBackdrop}
        initial={{ opacity: 0 }}
        animate={{
          opacity: show ? 1 : 0,
        }}
        style={{ pointerEvents: show ? 'auto' : 'none' }}
        onClick={() => setShow(false)}
        transition={{ duration: 0.29, easings: 'easeInOut' }}
      />
      <div className={styles.Infobox}>
        <div className={styles.InfoboxTrigger} onClick={() => setShow(!show)}>
          {label}
        </div>
        <motion.div
          className={styles.InfoboxContent}
          initial={{ opacity: 0, transform: 'translateY(0)' }}
          animate={{
            transform: show ? 'translateY(-100%)' : 'translateY(0)',
            opacity: show ? 1 : 0,
          }}
          style={{ pointerEvents: show ? 'auto' : 'none' }}
          transition={{ duration: 0.29, easings: 'easeInOut' }}
          exit={{ opacity: 0, transform: 'translateY(0)' }}
          onClick={() => setShow(false)}
        >
          {content}
          <Button wide>Okay</Button>
        </motion.div>
      </div>
    </>
  );
}

export default Infobox;
