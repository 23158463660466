import { motion } from 'framer-motion';
import styles from './TimeApproveEntry.module.scss';
import { WeekEntry } from '../../../types/Time';
import { Link } from 'react-router-dom';

function TimeApproveEntry({ weekNumber, startDate, endDate }: WeekEntry) {
  return (
    <motion.div
      variants={{
        hidden: {
          opacity: 0,
          y: 30,
        },
        visible: {
          opacity: 1,
          y: 0,
          transition: {
            duration: 0.3,
          },
        },
      }}
    >
      <Link
        className={styles.timeApproveEntry}
        to={`/time/approve/${weekNumber}-${startDate.getFullYear()}`}
        state={{ startDate, endDate }}
      >
        <div className={styles.timeApproveEntryLabel}>
          {startDate.toLocaleDateString('da-DK', {
            day: 'numeric',
            month: 'long',
          })}{' '}
          -{' '}
          {endDate.toLocaleDateString('da-DK', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          })}
        </div>
        <div className={styles.timeApproveEntryInner}>
          <div className={styles.timeApproveEntryTitle}>
            Godkend registreringer for uge {weekNumber}
          </div>
          <svg
            width="7"
            height="12"
            viewBox="0 0 7 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 11L5.29289 6.70711C5.68342 6.31658 5.68342 5.68342 5.29289 5.29289L1 1"
              stroke="#194A37"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </div>
      </Link>
    </motion.div>
  );
}

export default TimeApproveEntry;
