const DANISH_HOLIDAYS = [
  {
    label: 'Nytårsdag',
    value: '2024-01-01',
  },
  {
    label: 'Skærtorsdag',
    value: '2024-03-28',
  },
  {
    label: 'Langfredag',
    value: '2024-03-29',
  },
  {
    label: 'Påskedag',
    value: '2024-03-31',
  },
  {
    label: '2. påskedag',
    value: '2024-04-01',
  },
  {
    label: 'Kristi Himmelfartsdag',
    value: '2024-05-09',
  },
  {
    label: '2. pinsedag',
    value: '2024-05-20',
  },
  {
    label: 'Juleaften',
    value: '2024-12-24',
  },
  {
    label: 'Juledag',
    value: '2024-12-25',
  },
  {
    label: '2. juledag',
    value: '2024-12-26',
  },
  {
    label: 'Nytårsaften',
    value: '2024-12-31',
  },
  {
    label: 'Nytårsdag',
    value: '2025-01-01',
  },
  {
    label: 'Skærtorsdag',
    value: '2025-04-17',
  },
  {
    label: 'Langfredag',
    value: '2025-04-18',
  },
  {
    label: 'Påskedag',
    value: '2025-04-20',
  },
  {
    label: '2. påskedag',
    value: '2025-04-21',
  },
  {
    label: 'Kristi Himmelfartsdag',
    value: '2025-05-29',
  },
  {
    label: '2. pinsedag',
    value: '2025-06-09',
  },
  {
    label: 'Juleaften',
    value: '2024-12-25',
  },
  {
    label: 'Juledag',
    value: '2025-12-25',
  },
  {
    label: '2. juledag',
    value: '2025-12-26',
  },
  {
    label: 'Nytårsaften',
    value: '2025-12-31',
  },
  {
    label: 'Nytårsdag',
    value: '2026-01-01',
  },
  {
    label: 'Skærtorsdag',
    value: '2026-04-02',
  },
  {
    label: 'Langfredag',
    value: '2026-04-03',
  },
  {
    label: 'Påskedag',
    value: '2026-04-05',
  },
  {
    label: '2. påskedag',
    value: '2026-04-06',
  },
  {
    label: 'Kristi Himmelfartsdag',
    value: '2026-05-14',
  },
  {
    label: '2. pinsedag',
    value: '2026-05-25',
  },
  {
    label: 'Juleaften',
    value: '2026-12-24',
  },
  {
    label: 'Juledag',
    value: '2026-12-25',
  },
  {
    label: '2. juledag',
    value: '2026-12-26',
  },
  {
    label: 'Nytårsaften',
    value: '2026-12-31',
  },
  {
    label: 'Nytårsdag',
    value: '2027-01-01',
  },
  {
    label: 'Skærtorsdag',
    value: '2027-03-25',
  },
  {
    label: 'Langfredag',
    value: '2027-03-26',
  },
  {
    label: 'Påskedag',
    value: '2027-03-28',
  },
  {
    label: '2. påskedag',
    value: '2027-03-29',
  },
  {
    label: 'Kristi Himmelfartsdag',
    value: '2027-05-06',
  },
  {
    label: '2. pinsedag',
    value: '2027-05-17',
  },
  {
    label: 'Juleaften',
    value: '2027-12-24',
  },
  {
    label: 'Juledag',
    value: '2027-12-25',
  },
  {
    label: '2. juledag',
    value: '2027-12-26',
  },
  {
    label: 'Nytårsaften',
    value: '2027-12-31',
  },
  {
    label: 'Nytårsdag',
    value: '2028-01-01',
  },
  {
    label: 'Skærtorsdag',
    value: '2028-04-13',
  },
  {
    label: 'Langfredag',
    value: '2028-04-15',
  },
  {
    label: 'Påskedag',
    value: '2028-04-16',
  },
  {
    label: '2. påskedag',
    value: '2028-04-17',
  },
  {
    label: 'Kristi Himmelfartsdag',
    value: '2028-05-25',
  },
  {
    label: '2. pinsedag',
    value: '2028-06-05',
  },
  {
    label: 'Juleaften',
    value: '2028-12-24',
  },
  {
    label: 'Juledag',
    value: '2028-12-25',
  },
  {
    label: '2. juledag',
    value: '2028-12-26',
  },
  {
    label: 'Nytårsaften',
    value: '2028-12-31',
  },
];

export default DANISH_HOLIDAYS;
