import { AnimatePresence, motion } from 'framer-motion';
import styles from './TimeMonthList.module.scss';
import { Link, useParams } from 'react-router-dom';
import { isDateLocked } from '../../../utils/isDateLocked';
import { useContext } from 'react';
import { UserContext } from '../../../contexts/UserContext';
import { getCompanyStartDate } from '../../../utils/getCompanyStartDate';

function TimeMonthList() {
  const { date = new Date().toISOString().split('T')[0] } = useParams() as {
    date: string;
  };
  const { userInfo, company } = useContext(UserContext);

  // Determining the year and month from the parameter
  const currentYear = new Date(date).getFullYear();
  const currentMonth = new Date(date).getMonth();

  // Getting the day of the week for the first day of the month
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();

  // Adjusting for weeks starting on Monday (getDay returns 0 for Sunday, 1 for Monday, etc.)
  const blankDays = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1; // Adjust to start week on Monday

  // Days in the month
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

  // Creating the days array with blanks for alignment
  const daysArray = new Array(blankDays)
    .fill(null)
    .concat(Array.from({ length: daysInMonth }, (_, i) => i + 1));

  const MotionLink = motion(Link);

  return (
    <AnimatePresence>
      <motion.div
        className={styles.timeMonthList}
        key={currentMonth}
        variants={{
          visible: {
            transition: {
              delayChildren: 0.1,
              staggerChildren: 0.03,
            },
          },
          hidden: {
            transition: { staggerChildren: 0, staggerDirection: -1 },
          },
        }}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        {['Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør', 'Søn'].map((day, i) => (
          <div
            className={`${styles.timeMonthListItem} ${styles.timeMonthListItemLegend}`}
            key={i}
          >
            {day}
          </div>
        ))}
        {/* List out days of the month, based on the current month */}
        {daysArray.map((day, i) => {
          if (day === null) {
            // Render blank spaces for alignment
            return <div className={styles.timeMonthListItem} key={i}></div>;
          } else {
            const date = new Date(currentYear, currentMonth, day, 12, 0, 0);
            const isToday =
              new Date().getDate() === day &&
              new Date().getMonth() === currentMonth;
            const dayOfWeek = date.getDay();
            const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;

            const isLocked = isDateLocked(
              date,
              userInfo.timeLogApprovedWeeks,
              userInfo.startDate,
              getCompanyStartDate(company)
            );

            return (
              <MotionLink
                to={`/time/log/${date.toISOString().split('T')[0]}`}
                variants={{
                  hidden: {
                    opacity: 0,
                    y: 10,
                  },
                  visible: {
                    opacity: 1,
                    y: 0,
                    transition: {
                      duration: 0.1,
                    },
                  },
                }}
                className={`${styles.timeMonthListItem} ${
                  isToday ? styles.today : ''
                } ${isWeekend ? styles.weekend : ''}
                ${isLocked ? styles.locked : ''}
                `}
                key={i}
              >
                <h4>{day}</h4>
              </MotionLink>
            );
          }
        })}
      </motion.div>
    </AnimatePresence>
  );
}

export default TimeMonthList;
