import { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { UserContext } from './contexts/UserContext';
import { AdminContext } from './contexts/AdminContext';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';
import { db, auth } from './firebase-config';
import { CompanyContextInterface } from './types/Company';
import { UserInfoContextInterface } from './types/User';
import Preloader from './components/preloader/Preloader';
import LoginView from './views/auth/LoginView';
import ForgotPasswordView from './views/auth/ForgotPasswordView';
import NoAccessView from './views/auth/NoAccessView';
import TimeLogView from './views/time/TimeLogView';
import TimeYearView from './views/time/TimeYearView';
import TimeMonthView from './views/time/TimeMonthView';
import TimeOverviewView from './views/time/TimeOverviewView';
import TimeLogEntries from './components/time/log/TimeLogEntries';
import TimeMonthList from './components/time/month/TimeMonthList';
import TimeYearList from './components/time/year/TimeYearList';
import TimeApproveView from './views/time/TimeApproveView';
import TimeApproveEntryView from './views/time/TimeApproveEntryView';
import * as Sentry from '@sentry/react';

// import WhistleblowerOverviewView from './views/whistleblower/WhistleblowerOverviewView';
// import WhistleblowerDetailView from './views/whistleblower/WhistleblowerDetailView';
// import WhistleblowerCreateView from './views/whistleblower/WhistleblowerCreateView';

import '@fontsource-variable/plus-jakarta-sans';
import '@fontsource/material-icons-two-tone';

function App() {
  const [admin, setAdmin] = useState({
    firstName: '',
    lastName: '',
  });
  const [user, setUser] = useState({
    uid: '',
  });
  const [company, setCompany] = useState({
    id: '',
    name: '',
    timeLogSettings: [],
    timeOffTypes: [],
  } as CompanyContextInterface);
  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    profilePhotoUrl: '',
    companyId: '',
    timeLogSettings: [],
    timeLogApprovedWeeks: [],
    timeLogWarnings: [],
    employmentStatus: 'active',
    settings: {
      timeRegistration: {
        exempt: false,
        individualTimeLogSettings: false,
      },
    },
  } as UserInfoContextInterface);
  const [isLoading, setIsLoading] = useState(true);

  const fetchAdminInfo = async (companyId: string) => {
    const adminData = await fetch('/api/company/admin', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ companyId }),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    });
    setAdmin(adminData);
  };

  const fetchCompanyInfo = (companyId: string) => {
    const companyDocRef = doc(db, 'companies', companyId);
    return onSnapshot(companyDocRef, (snapshot) => {
      if (snapshot.exists()) {
        // Get company data from the database
        setCompany({
          ...snapshot.data(),
        } as CompanyContextInterface);

        setIsLoading(false);
      } else {
        // Handle UI for user not found
        setIsLoading(false);
      }
    });
  };

  const fetchUserInfo = (userId: string) => {
    const userDocRef = doc(db, 'users', userId);
    return onSnapshot(userDocRef, (snapshot) => {
      if (snapshot.exists()) {
        // Get user data from the database
        setUserInfo({
          ...snapshot.data(),
        } as UserInfoContextInterface);

        // Update Sentry user context
        Sentry.setUser({
          id: userId,
          companyId: snapshot.data().companyId,
          email: snapshot.data().email,
        });

        setIsLoading(false);
      } else {
        // Handle UI for user not found
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser: any) => {
      // Set loading to true while the app is loading after a user logs in
      setIsLoading(true);

      setUser(currentUser);

      if (!currentUser) {
        // User not logged in
        setUserInfo({
          firstName: '',
          lastName: '',
          profilePhotoUrl: '',
          companyId: '',
          timeLogSettings: [],
          timeLogApprovedWeeks: [],
          timeLogWarnings: [],
          employmentStatus: 'active',
          settings: {
            timeRegistration: {
              exempt: false,
              individualTimeLogSettings: false,
            },
          },
        } as UserInfoContextInterface);
        setIsLoading(false);
      } else {
        // Fetch user details only if the user has access to the app
        currentUser.getIdTokenResult(true).then((idTokenResult: any) => {
          // Set up promise chain to fetch all necessary data before rendering the app
          const promises = [];

          // Fetch admin details
          if (idTokenResult.claims.companyId) {
            promises.push(fetchAdminInfo(idTokenResult.claims.companyId));
          }

          // Fetch user details
          if (
            idTokenResult.claims.companyId &&
            idTokenResult.claims.mobileAccess
          ) {
            // Check if custom claims are already set
            promises.push(fetchUserInfo(currentUser.uid));
            promises.push(fetchCompanyInfo(idTokenResult.claims.companyId));
          }

          // Wait for all promises to resolve
          Promise.all(promises).then(() => {
            setIsLoading(false);

            // Set up the app to receive push notifications
            if (!startiapp.isRunningInApp()) return;

            startiapp.addEventListener('ready', async function () {
              // Ask for permission to send notifications
              await startiapp.PushNotification.requestAccess();

              // Try and subscribe to the weekly reminder topic
              await startiapp.PushNotification.subscribeToTopics([
                'weekly-reminder',
              ]);
            });
          });
        });
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (isLoading) return;
    // Check if the app is running in the Starti app
    if (!startiapp.isRunningInApp()) return;

    startiapp.addEventListener('ready', async function () {
      // Tell the app we are ready to receive events
      startiapp.initialize();

      // Get the app version
      console.log('App version:', await startiapp.App.version());
    });
  }, [isLoading]);

  if (isLoading) {
    // Show loading spinner or blank screen while the app is loading
    return <Preloader />;
  }

  return (
    <>
      <UserContext.Provider
        value={{
          user,
          userInfo,
          company,
        }}
      >
        <AdminContext.Provider
          value={{
            ...admin,
          }}
        >
          <BrowserRouter>
            <Routes>
              {user && userInfo.companyId ? (
                <>
                  <Route path="/" element={<TimeLogView />}>
                    <Route path=":date" element={<TimeLogEntries />} />
                  </Route>
                  <Route path="/time/log/" element={<TimeLogView />}>
                    <Route path=":date" element={<TimeLogEntries />} />
                  </Route>
                  <Route path="/time/month/" element={<TimeMonthView />}>
                    <Route path=":date" element={<TimeMonthList />} />
                  </Route>
                  <Route path="/time/year/" element={<TimeYearView />}>
                    <Route path=":date" element={<TimeYearList />} />
                  </Route>
                  <Route
                    path="/time/overview/"
                    element={<TimeOverviewView />}
                  />
                  <Route path="/time/approve/" element={<TimeApproveView />} />
                  <Route
                    path="/time/approve/:slug"
                    element={<TimeApproveEntryView />}
                  />

                  {/* <Route
                    path="/whistleblower/overview/"
                    element={<WhistleblowerOverviewView />}
                  />
                  <Route
                    path="/whistleblower/detail/:id"
                    element={<WhistleblowerDetailView />}
                  />
                  <Route
                    path="/whistleblower/create/"
                    element={<WhistleblowerCreateView />}
                  /> */}
                </>
              ) : user ? (
                <Route path="*" element={<NoAccessView />} />
              ) : (
                <>
                  <Route path="*" element={<LoginView />} />
                  <Route
                    path="/forgot-password"
                    element={<ForgotPasswordView />}
                  />
                </>
              )}
            </Routes>
          </BrowserRouter>
        </AdminContext.Provider>
      </UserContext.Provider>
    </>
  );
}

export default App;
