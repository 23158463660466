import { Outlet, useParams } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import TimeTop from '../../components/time/timetop/TimeTop';
import DefaultLayout from '../../layouts/Default';
import TimeLogEntries from '../../components/time/log/TimeLogEntries';
import TimeLogDays from '../../components/time/log/TimeLogDays';

function TimeLogView() {
  const { date } = useParams() as {
    date: string;
  };

  return (
    <DefaultLayout>
      <TimeTop mode="week" />
      <TimeLogDays date={date} />
      <AnimatePresence>
        {date ? <Outlet key={date} /> : <TimeLogEntries key={date} />}
      </AnimatePresence>
    </DefaultLayout>
  );
}

export default TimeLogView;
