import { useContext } from 'react';
import { UserContext } from '../../../contexts/UserContext';
import Panel from '../../panel/Panel';
import styles from './TimeSummary.module.scss';
import Infobox from '../../infobox/Infobox';
import { currentTimeLogSetting } from '../../../utils/currentTimeLogSetting';
function TimeSummary({
  numbers,
  showTotal = true,
  miniFeed = false,
}: {
  showTotal?: boolean;
  miniFeed?: boolean;
  numbers: {
    normTime: number;
    total: number;
    overtime: {
      label: string;
      count: number;
      hours: number;
    };
    timeOff: {
      label: string;
      count: number;
      hours: number;
    };
    holiday: {
      label: string;
      count: number;
      hours: number;
    };
    sick: {
      label: string;
      count: number;
      hours: number;
    };
    work: {
      label: string;
      count: number;
      hours: number;
    };
  };
}) {
  const { userInfo, company } = useContext(UserContext);
  // Get the time log setting for the selected date so we know if they are paid hourly or not
  const timeLogSetting = currentTimeLogSetting(company, userInfo, new Date());

  return (
    <>
      <div
        className={`${styles.TimeSummary} ${
          miniFeed && styles.TimeSummaryMiniFeed
        }`}
      >
        {numbers.overtime.hours > 0 && (
          <div className={styles.TimeSummaryRow}>
            <div className={styles.TimeSummaryRowType}>
              <div className={styles.TimeSummaryRowTypeLabel}>
                {numbers.overtime.label}
              </div>
              {!miniFeed && (
                <div className={styles.TimeSummaryRowTypeAmount}>
                  {numbers.overtime.count}{' '}
                  {`registrering${numbers.overtime.count > 1 ? 'er' : ''}`}
                </div>
              )}
            </div>
            <div className={styles.TimeSummaryRowPercentage}>
              {((numbers.overtime.hours / numbers.total) * 100).toFixed(1)}%
            </div>
            <div className={styles.TimeSummaryRowValue}>
              {numbers.overtime.hours}{' '}
              {`time${numbers.overtime.hours > 1 ? 'r' : ''}`}
            </div>
          </div>
        )}
        {numbers.timeOff.hours > 0 && (
          <div className={styles.TimeSummaryRow}>
            <div className={styles.TimeSummaryRowType}>
              <div className={styles.TimeSummaryRowTypeLabel}>
                {numbers.timeOff.label}
              </div>
              {!miniFeed && (
                <div className={styles.TimeSummaryRowTypeAmount}>
                  {numbers.timeOff.count}{' '}
                  {`registrering${numbers.timeOff.count > 1 ? 'er' : ''}`}
                </div>
              )}
            </div>
            <div className={styles.TimeSummaryRowPercentage}>
              {((numbers.timeOff.hours / numbers.total) * 100).toFixed(1)}%
            </div>
            <div className={styles.TimeSummaryRowValue}>
              {numbers.timeOff.hours}{' '}
              {`time${numbers.timeOff.hours > 1 ? 'r' : ''}`}
            </div>
          </div>
        )}
        {numbers.holiday.hours > 0 && (
          <div className={styles.TimeSummaryRow}>
            <div className={styles.TimeSummaryRowType}>
              <div className={styles.TimeSummaryRowTypeLabel}>
                {numbers.holiday.label}
              </div>
              {!miniFeed && (
                <div className={styles.TimeSummaryRowTypeAmount}>
                  {numbers.holiday.count}{' '}
                  {`registrering${numbers.holiday.count > 1 ? 'er' : ''}`}
                </div>
              )}
            </div>
            <div className={styles.TimeSummaryRowPercentage}>
              {((numbers.holiday.hours / numbers.total) * 100).toFixed(1)}%
            </div>
            <div className={styles.TimeSummaryRowValue}>
              {numbers.holiday.hours}{' '}
              {`time${numbers.holiday.hours > 1 ? 'r' : ''}`}
            </div>
          </div>
        )}
        {numbers.sick.hours > 0 && (
          <div className={styles.TimeSummaryRow}>
            <div className={styles.TimeSummaryRowType}>
              <div className={styles.TimeSummaryRowTypeLabel}>
                {numbers.sick.label}
              </div>
              {!miniFeed && (
                <div className={styles.TimeSummaryRowTypeAmount}>
                  {numbers.sick.count}{' '}
                  {`registrering${numbers.sick.count > 1 ? 'er' : ''}`}
                </div>
              )}
            </div>
            <div className={styles.TimeSummaryRowPercentage}>
              {((numbers.sick.hours / numbers.total) * 100).toFixed(1)}%
            </div>
            <div className={styles.TimeSummaryRowValue}>
              {numbers.sick.hours} {`time${numbers.sick.hours > 1 ? 'r' : ''}`}
            </div>
          </div>
        )}
        {numbers.work.hours > 0 && (
          <div className={styles.TimeSummaryRow}>
            <div className={styles.TimeSummaryRowType}>
              <div className={styles.TimeSummaryRowTypeLabel}>
                {numbers.work.label}
              </div>
              {!miniFeed && (
                <div className={styles.TimeSummaryRowTypeAmount}>
                  {numbers.work.count}{' '}
                  {`registrering${numbers.work.count > 1 ? 'er' : ''}`}
                </div>
              )}
            </div>
            <div className={styles.TimeSummaryRowPercentage}>
              {((numbers.work.hours / numbers.total) * 100).toFixed(1)}%
            </div>
            <div className={styles.TimeSummaryRowValue}>
              {numbers.work.hours} {`time${numbers.work.hours > 1 ? 'r' : ''}`}
            </div>
          </div>
        )}
        {miniFeed && numbers.total > 0 && (
          <div
            className={`${styles.TimeSummaryRow} ${styles.TimeSummaryRowTotal}`}
          >
            <div className={styles.TimeSummaryRowType}>
              <div className={styles.TimeSummaryRowTypeLabel}>Total</div>
            </div>
            <div className={styles.TimeSummaryRowValue}>
              {numbers.total} timer
            </div>
          </div>
        )}
        {numbers.total === 0 && (
          <Panel size="small">
            <div className={styles.TimeSummaryNoData}>
              <h5>Der er ingen arbejdstid for denne periode.</h5>
            </div>
          </Panel>
        )}
      </div>
      {showTotal && numbers.total > 0 && (
        <>
          <Infobox
            label="Sådan udregnes tallene"
            content={
              <>
                <p>
                  <strong>Total</strong> er summen af alle timer i perioden,
                  inklusiv arbejde uden for normtid, fridage, sygedage og ferie.
                </p>
                <p>
                  <strong>Normtid for perioden</strong> er summen af den
                  forventede arbejdstid, ud fra dit skema i perioden. Hvis du er
                  timelønnet, er normtiden ikke relevant.
                </p>
              </>
            }
          />
          <div className={styles.TimeSummaryTotal}>
            <div>
              <span>Total</span>
              <span>{numbers.total} timer</span>
            </div>
            {!timeLogSetting?.isPaidHourly && (
              <div>
                <span>Normtid for perioden</span>
                <span>{numbers.normTime} timer</span>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default TimeSummary;
