import { createContext } from 'react';
import { CompanyContextInterface } from '../types/Company';
import { UserInfoContextInterface } from '../types/User';

export const UserContext = createContext({
  user: {
    uid: '',
  },
  company: {
    id: '',
    name: '',
    timeLogSettings: [],
    timeOffTypes: [],
  } as CompanyContextInterface,
  userInfo: {
    firstName: '',
    lastName: '',
    profilePhotoUrl: '',
    companyId: '',
    timeLogSettings: [],
    timeLogApprovedWeeks: [],
    timeLogWarnings: [],
    employmentStatus: 'active',
    settings: {
      timeRegistration: {
        exempt: false,
        individualTimeLogSettings: false,
      },
    },
  } as UserInfoContextInterface,
});
