// utils/getCompanyStartDate.ts

import { CompanyContextInterface } from '../types/Company';
import { TimeLogSetting } from '../types/User';

/**
 * Retrieves the company's start date based on the earliest timeLogSetting.
 *
 * @param company - The company context interface containing timeLogSettings.
 * @returns The company's start date as a JavaScript Date object, or undefined if not found.
 */
export const getCompanyStartDate = (
  company: CompanyContextInterface
): Date | undefined => {
  if (!company.timeLogSettings || company.timeLogSettings.length === 0) {
    return undefined;
  }

  // Find the earliest setting based on createdDate
  const earliestSetting = company.timeLogSettings.reduce(
    (earliest: TimeLogSetting, current: TimeLogSetting) => {
      const currentDate = new Date(current.createdDate.seconds * 1000);
      const earliestDate = new Date(earliest.createdDate.seconds * 1000);
      return currentDate < earliestDate ? current : earliest;
    },
    company.timeLogSettings[0]
  );

  return new Date(earliestSetting.createdDate.seconds * 1000);
};
