import { useEffect } from 'react';
import { TimeModalProvider } from '../contexts/TimeModalContext';
import styles from './Default.module.scss';
import { MenuProvider } from '../contexts/MenuContext';

function DefaultLayout({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    // Check if the app is running in the Starti app
    if (!startiapp.isRunningInApp()) return;

    startiapp.App.setStatusBar({
      hideText: false,
      darkContent: false,
      removeSafeArea: true,
    });
  }, []);

  return (
    <div className={styles.defaultLayout}>
      <TimeModalProvider>
        <MenuProvider>{children}</MenuProvider>
      </TimeModalProvider>
    </div>
  );
}

export default DefaultLayout;
