// utils/isDateLocked.ts

import { Timestamp } from 'firebase/firestore';
import { currentWeek } from './currentWeek';

/**
 * Determines if a given date is locked based on approved weeks,
 * user's start date, company's start date, and a special locked date.
 *
 * @param dateObj - The date to check.
 * @param approvedWeeks - An array of approved week keys in the format "weekNumber-year".
 * @param userStartDate - The user's start date as a Firebase Timestamp.
 * @param companyStartDate - The company's start date as a JavaScript Date object.
 * @returns A boolean indicating whether the date is locked.
 */
export const isDateLocked = (
  dateObj: Date,
  approvedWeeks: string[],
  userStartDate?: Timestamp,
  companyStartDate?: Date
): boolean => {
  // **Special Rule:** Lock any date before August 5, 2024
  const specialLockedDate = new Date('2024-08-05');
  if (dateObj < specialLockedDate) {
    return true;
  }

  // **Construct the week key for the given date**
  const weekNumber = currentWeek(dateObj);
  const year = dateObj.getFullYear();
  const weekKey = `${weekNumber}-${year}`;

  // **Rule 1:** If the week is already approved, lock the date
  if (approvedWeeks?.includes(weekKey)) {
    return true;
  }

  // **Rule 2:** Lock if the date is before the later of userStartDate and companyStartDate
  let lockDate: Date | undefined;

  if (userStartDate && companyStartDate) {
    const userStart = userStartDate.toDate();
    lockDate = userStart > companyStartDate ? userStart : companyStartDate;
  } else if (companyStartDate) {
    lockDate = companyStartDate;
  } else if (userStartDate) {
    lockDate = userStartDate.toDate();
  }

  if (lockDate && dateObj < lockDate) {
    return true;
  }

  // **Default:** Date is not locked
  return false;
};
