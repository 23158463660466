// TimeApproveEntryView.tsx

import styles from './TimeApproveEntryView.module.scss';
import {
  doc,
  setDoc,
  arrayUnion,
  collection,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import { db } from '../../firebase-config';
import { UserContext } from '../../contexts/UserContext';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Topbar from '../../components/topbar/Topbar';
import DefaultLayout from '../../layouts/Default';
import Button from '../../components/button/Button';
import { TimeLogInterface } from '../../types/Time';
import TimeSummary from '../../components/time/summary/TimeSummary';
import { calculateSummaryNumbers } from '../../utils/calculateSummaryNumbers';
import TimeWeekSummary from '../../components/time/weeksummary/TimeWeekSummary';

function TimeApproveEntryView() {
  const { slug } = useParams<{ slug: string }>();
  const locationState = useLocation().state as {
    startDate: Date;
    endDate: Date;
  };
  const { user, userInfo, company } = useContext(UserContext);
  const navigate = useNavigate();
  const [logs, setLogs] = useState<TimeLogInterface[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [numbers, setNumbers] = useState({
    normTime: 0,
    total: 0,
    overtime: {
      label: 'Arbejdstid uden for norm',
      count: 0,
      hours: 0,
    },
    timeOff: {
      label: 'Fri',
      count: 0,
      hours: 0,
    },
    holiday: {
      label: 'Ferie',
      count: 0,
      hours: 0,
    },
    sick: {
      label: 'Syg',
      count: 0,
      hours: 0,
    },
    work: {
      label: 'Arbejdstid',
      count: 0,
      hours: 0,
    },
  });

  const week = slug?.split('-')[0];

  const handleApprove = useCallback(async () => {
    setSubmitting(true);
    // See if we have an existing document or new document for the date currently being processed
    const userDocRef = doc(db, 'users', user.uid);

    try {
      await setDoc(
        userDocRef,
        {
          timeLogApprovedWeeks: arrayUnion(slug),
        },
        { merge: true }
      );

      // Handle success
      navigate('/time/approve', {
        replace: true,
      });
    } catch (error) {
      console.error('Error setting document:', error);

      // Set feedback if there is an error
      setFeedback('Der skete en fejl under oprettelsen af registreringen.');

      setSubmitting(false);
    }
  }, [slug, user.uid, navigate]);

  const fetchLogs = async () => {
    // Fetch the logs collection of a user from the database based on the dates
    const timeLogsCollectionRef = collection(db, 'users', user.uid, 'logs');
    const q = query(
      timeLogsCollectionRef,
      where('date', '>=', locationState?.startDate),
      where('date', '<=', locationState?.endDate)
    );

    const querySnapshot = await getDocs(q);

    let logs: TimeLogInterface[] = [];
    querySnapshot.forEach((doc) => {
      logs.push({
        id: doc.id,
        data: {
          date: doc.data().date,
          entries: doc.data().entries,
        },
      });
    });

    // Calculate numbers based on logs
    setNumbers(
      calculateSummaryNumbers(
        logs,
        locationState.startDate,
        locationState.endDate,
        company,
        userInfo
      )
    );

    // Set the logs for the component
    setLogs(logs);
  };

  useEffect(() => {
    if (
      !slug ||
      !locationState ||
      !locationState.startDate ||
      !locationState.endDate
    ) {
      return navigate('/time/approve', {
        replace: true,
      });
    }

    fetchLogs();
  }, []);

  if (
    !slug ||
    !locationState ||
    !locationState.startDate ||
    !locationState.endDate
  ) {
    return null;
  }

  return (
    <DefaultLayout>
      <Topbar
        left={
          <Button
            href={`/time/approve/`}
            buttonStyle="rounded-faded"
            size="tiny"
          >
            <svg
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 12L1.70711 7.70711C1.31658 7.31658 1.31658 6.68342 1.70711 6.29289L6 2"
                stroke="#F5F7F3"
                strokeWidth="2.5"
                strokeLinecap="round"
              />
            </svg>
          </Button>
        }
        center={<h4>Godkend uge {week}</h4>}
      />

      <TimeSummary showTotal={false} miniFeed numbers={numbers} />
      <TimeWeekSummary
        startDate={locationState.startDate}
        endDate={locationState.endDate}
        logs={logs}
      />
      <div className={styles.timeApproveEntryViewActions}>
        {feedback && (
          <div className={styles.timeApproveEntryViewActionsFeedback}>
            {feedback}
          </div>
        )}
        <Button wide onClick={handleApprove} disabled={submitting}>
          Godkend og lås uge
        </Button>
      </div>
    </DefaultLayout>
  );
}

export default TimeApproveEntryView;
