import styles from './TimeApproveEmptyState.module.scss';

function TimeApproveEmptyState() {
  return (
    <div className={styles.timeApproveEmptyState}>
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="64" height="64" rx="32" fill="#CDE4BA" />
        <path
          d="M27.4146 37.0228L21.679 31.2873L21.3962 31.0044L21.1133 31.2873L19.1072 33.2934L18.8243 33.5762L19.1072 33.8591L27.1317 41.8836L27.4146 42.1665L27.6974 41.8836L44.8929 24.6882L45.1757 24.4053L44.8929 24.1225L42.8867 22.1164L42.6039 21.8335L42.321 22.1164L27.4146 37.0228Z"
          fill="#3D8545"
          stroke="#3D8545"
          strokeWidth="0.8"
        />
      </svg>

      <h4 className={styles.timeApproveEmptyStateLabel}>
        Du har ingen uger til godkendelse.
      </h4>
    </div>
  );
}

export default TimeApproveEmptyState;
