export function timeFormat(time?: Date, locale = 'da-DK') {
  if (!time) {
    return '';
  }
  return time
    .toLocaleTimeString(locale, {
      timeStyle: 'short',
    })
    .replace('.', ':');
}
