import styles from './Preloader.module.scss';

export default function Preloader() {
  return (
    <div className={styles.preloader}>
      <div className={styles.preloader__inner}>
        <div className={styles.preloader__leaf__1}></div>
        <div className={styles.preloader__leaf__2}></div>
      </div>
    </div>
  );
}
