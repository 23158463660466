import styles from './TimeLogEntry.module.scss';
import Panel from '../../panel/Panel';
import { motion } from 'framer-motion';
import { Timestamp } from 'firebase/firestore';
import DANISH_HOLIDAYS from '../../../constants/danishHolidays';

function TimeLogEntryWorkHours({
  date,
  timeLogSetting,
}: {
  date: Date;
  timeLogSetting: {
    breakDuration: number;
    paidBreak: boolean;
    from: Timestamp;
    end: Timestamp;
    notWorking: boolean;
    isPaidHourly: boolean;
  };
}) {
  const workingHours =
    (timeLogSetting.end.toMillis() - timeLogSetting.from.toMillis()) /
    1000 /
    60 /
    60;

  // If paid break is enabled, break duration is 0
  let breakDuration = timeLogSetting.paidBreak
    ? 0
    : timeLogSetting.notWorking
    ? 0
    : workingHours === 0
    ? 0
    : timeLogSetting.breakDuration;

  // Check if the day is a Danish holiday
  const isHoliday = DANISH_HOLIDAYS.filter(
    (holiday) => holiday.value === date.toISOString().split('T')[0]
  )[0];

  if (isHoliday) {
    return <></>;
  }

  if (timeLogSetting.notWorking) {
    return <></>;
  }

  return (
    <motion.div
      variants={{
        hidden: {
          opacity: 0,
          y: 30,
        },
        visible: {
          opacity: 1,
          y: 0,
          transition: {
            duration: 0.3,
          },
        },
      }}
    >
      <Panel size="small" type="ghost">
        <div className={styles.timeLogEntryInner}>
          <span className={styles.timeLogEntryType}>Normal arbejdstid</span>
          <span
            className={`${styles.timeLogEntryInnerHours} ${styles.timeLogEntryInnerHoursNeutral}`}
          >
            {(workingHours - breakDuration / 60).toLocaleString('da-DK', {
              minimumFractionDigits: 1,
            })}{' '}
            timer
          </span>
        </div>
        <span className={styles.timeLogEntryInnerTime}>
          {timeLogSetting.paidBreak ? 'Inkl.' : 'Eksl.'}{' '}
          {!timeLogSetting.paidBreak ? `${breakDuration} minutters` : ''} pause
        </span>
      </Panel>
    </motion.div>
  );
}

export default TimeLogEntryWorkHours;
