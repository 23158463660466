import styles from './TimeLogEntry.module.scss';
import { TimeLogEntryType, TimeLogInterface } from '../../../types/Time';
import Panel from '../../panel/Panel';
import { timeFormat } from '../../../utils/timeFormat';
import { motion } from 'framer-motion';
import TIME_LOG_ENTRY_TYPES from '../../../constants/timeLogEntryTypes';
import { useContext } from 'react';
import { TimeModalContext } from '../../../contexts/TimeModalContext';
import { UserContext } from '../../../contexts/UserContext';
import { isDateLocked } from '../../../utils/isDateLocked';
import { getCompanyStartDate } from '../../../utils/getCompanyStartDate';

interface TimeLogEntryProps {
  timeLog: TimeLogInterface;
  entry: TimeLogEntryType;
}

function TimeLogEntry({ timeLog, entry }: TimeLogEntryProps) {
  const { company, userInfo } = useContext(UserContext);
  const { open } = useContext(TimeModalContext);

  const isLocked = isDateLocked(
    new Date(timeLog.id),
    userInfo.timeLogApprovedWeeks,
    userInfo.startDate,
    getCompanyStartDate(company)
  );

  const handleOpen = () => {
    if (isLocked) return;
    open(timeLog, entry);
  };

  const timeOffType = company.timeOffTypes?.find(
    (type) => type.value === entry.timeOffType
  );

  const isNegative =
    entry.type === 'sickLeave' ||
    entry.type === 'holiday' ||
    entry.type === 'timeOff';
  return (
    <motion.div
      variants={{
        hidden: {
          opacity: 0,
          y: 30,
        },
        visible: {
          opacity: 1,
          y: 0,
          transition: {
            duration: 0.3,
          },
        },
      }}
    >
      <Panel size="small">
        <div onClick={handleOpen}>
          <span className={styles.timeLogEntryType}>
            {
              TIME_LOG_ENTRY_TYPES.find((type) => type.value === entry.type)
                ?.label
            }
            {timeOffType && ` · ${timeOffType.label}`}
          </span>
          <div className={styles.timeLogEntryInner}>
            <span className={styles.timeLogEntryInnerTime}>
              {timeFormat(entry.startTime.toDate(), 'da-DK')}
              {' - '}
              {timeFormat(entry.endTime.toDate(), 'da-DK')}
            </span>
            <span
              className={
                styles.timeLogEntryInnerHours +
                (isNegative ? ' ' + styles.timeLogEntryInnerHoursNegative : '')
              }
            >
              {isNegative ? '-' : '+'}
              {entry.hours.toLocaleString('da-DK', {
                minimumFractionDigits: 1,
              })}{' '}
              timer
            </span>
          </div>
          {entry.note && (
            <span className={styles.timeLogEntryNote}>{entry.note}</span>
          )}
        </div>
      </Panel>
    </motion.div>
  );
}

export default TimeLogEntry;
