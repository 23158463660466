import { useContext } from 'react';
import { isDateLocked } from '../../../utils/isDateLocked';
import styles from './TimeLogDays.module.scss';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../contexts/UserContext';
import { getCompanyStartDate } from '../../../utils/getCompanyStartDate';

function TimeLogDays({
  date = new Date().toISOString().split('T')[0],
}: {
  date: string;
}) {
  const { userInfo, company } = useContext(UserContext);
  const dateObj = new Date(date);
  const days = [];
  const day = new Date(dateObj.getTime());
  // Adjust to start the week from Monday
  const dayOfWeek = day.getDay();
  const difference = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // Calculate difference from Monday
  day.setDate(day.getDate() - difference); // Set to Monday of the current week

  for (let i = 0; i < 7; i++) {
    days.push({
      date: new Date(day.getTime()),
      dayOfWeekShort: day
        .toLocaleString('da-DK', { weekday: 'short' })
        .slice(0, 2), // e.g., "Mon"
      dayOfMonth: day.getDate(),
      monthShort: day
        .toLocaleString('da-DK', { month: 'short' })
        .replace('.', '')
        .slice(0, 3),
    });
    day.setDate(day.getDate() + 1);
  }

  return (
    <div className={styles.TimeLogDays}>
      {days.map((day) => {
        const selectedDate = date === day.date.toISOString().split('T')[0];
        const today =
          new Date().toISOString().split('T')[0] ===
          day.date.toISOString().split('T')[0];

        const isLocked = isDateLocked(
          day.date,
          userInfo.timeLogApprovedWeeks,
          userInfo.startDate,
          getCompanyStartDate(company)
        );

        return (
          <Link
            key={day.date.toISOString()}
            to={`/time/log/${day.date.toISOString().split('T')[0]}`}
            className={`${styles.TimeLogDaysItem}
                ${selectedDate && styles.TimeLogDaysSelected}
                ${today && styles.TimeLogDaysToday}
                ${isLocked && styles.TimeLogDaysLocked}`}
          >
            <div className={styles.TimeLogDaysItemDay}>
              {day.dayOfWeekShort}
            </div>
            <h4 className={styles.TimeLogDaysItemDate}>{day.dayOfMonth}</h4>
            <div className={styles.TimeLogDaysItemMonth}>{day.monthShort}</div>
          </Link>
        );
      })}
    </div>
  );
}

export default TimeLogDays;
