import styles from './TimeLogSummary.module.scss';
import { TimeLogInterface } from '../../../types/Time';
import { Timestamp } from 'firebase/firestore';
import DANISH_HOLIDAYS from '../../../constants/danishHolidays';
import { useParams } from 'react-router-dom';

interface TimeLogSummaryProps {
  timeLog?: TimeLogInterface;
  timeLogSetting: {
    breakDuration: number;
    paidBreak: boolean;
    from: Timestamp;
    end: Timestamp;
    notWorking: boolean;
    isPaidHourly: boolean;
  };
}

function TimeLogSummary({ timeLog, timeLogSetting }: TimeLogSummaryProps) {
  const { date = new Date().toISOString().split('T')[0] } = useParams() as {
    date: string;
  };

  // Check if the day is a Danish holiday
  const isHoliday = DANISH_HOLIDAYS.filter(
    (holiday) => holiday.value === date
  )[0];

  let workingHours =
    (timeLogSetting.end.toMillis() - timeLogSetting.from.toMillis()) /
    1000 /
    60 /
    60;

  // Handle break duration
  if (timeLogSetting.breakDuration > 0) {
    // Break duration is saved in minutes, e.g. 30 minutes, so we need to divide by 60 to get the hours
    let breakDuration = timeLogSetting.breakDuration / 60;

    // If paid break is enabled, break duration is 0 as it is already included in the working hours
    if (timeLogSetting.paidBreak) {
      breakDuration = 0;
    }

    workingHours -= breakDuration;
  }

  // If the user is not working, set working hours to 0
  if (timeLogSetting.notWorking || isHoliday) {
    workingHours = 0;
  }

  // Handle timelog entries
  timeLog?.data?.entries.forEach((entry) => {
    if (entry.type === 'sickLeave') {
      workingHours -= entry.hours;
    }
    if (entry.type === 'holiday') {
      workingHours -= entry.hours;
    }
    if (entry.type === 'timeOff') {
      workingHours -= entry.hours;
    }
    if (entry.type === 'overtime') {
      workingHours += entry.hours;
    }
    if (entry.type === 'work') {
      workingHours += entry.hours;
    }
  });

  return (
    <>
      <div className={styles.timeLogSummarySpacer} />
      <div className={styles.timeLogSummary}>
        <div>
          <span>Total arbejdstid</span>
          <span>
            {workingHours.toLocaleString('da-DK', {
              minimumFractionDigits: 1,
            })}{' '}
            timer
          </span>
        </div>
      </div>
    </>
  );
}

export default TimeLogSummary;
