import styles from './InputField.module.scss';

function InputField({
  className,
  required = false,
  name,
  label,
  value,
  defaultValue,
  onChange,
  placeholder,
  type = 'text',
  help,
  error,
  disabled,
  visibleLabel = false,
  forwardRef,
  ...props
}: {
  className?: string;
  required?: boolean;
  name: string;
  label: string;
  value?: string | number;
  defaultValue?: string | number;
  onChange?: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  placeholder?: string;
  type?:
    | 'text'
    | 'email'
    | 'password'
    | 'number'
    | 'checkbox'
    | 'select'
    | 'time'
    | 'date'
    | 'textarea'
    | 'switch';
  help?: string;
  error?: string;
  disabled?: boolean;
  options?: { value: string | number; label: string }[];
  visibleLabel?: boolean;
  forwardRef?: React.RefObject<
    HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
  >;
}) {
  if (type === 'checkbox') {
    return (
      <div
        className={`${styles.inputField} ${styles.inputFieldCheckbox} ${
          error && styles.inputFieldError
        } ${visibleLabel && styles.inputFieldVisibleLabel} ${className ?? ''}`}
      >
        <label>
          <input
            name={name}
            required={required}
            type={type}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            ref={forwardRef as React.RefObject<HTMLInputElement>}
            onChange={onChange}
            {...props}
          />
          <span dangerouslySetInnerHTML={{ __html: label }} />
        </label>
        {help && <span className={styles.help}>{help}</span>}
        {error && <span className={styles.error}>{error}</span>}
      </div>
    );
  }

  if (type === 'switch') {
    return (
      <div className={`${styles.inputField} ${styles.inputFieldSwitch} `}>
        {label && <span>{label}</span>}
        <div>
          <input
            name={name}
            id={name}
            type="checkbox"
            value={value}
            onChange={onChange}
            ref={forwardRef as React.RefObject<HTMLInputElement>}
            {...props}
          />
          <label htmlFor={name}></label>
        </div>
        {help && <span className={styles.help}>{help}</span>}
      </div>
    );
  }

  if (type === 'select') {
    return (
      <div
        className={`${styles.inputField} ${styles.inputFieldSelect} ${
          error && styles.inputFieldError
        } ${visibleLabel && styles.inputFieldVisibleLabel} ${className ?? ''}`}
      >
        <select
          name={name}
          required={required}
          value={value}
          onChange={onChange}
          disabled={disabled}
          ref={forwardRef as React.RefObject<HTMLSelectElement>}
          {...props}
        >
          <option disabled value="">
            {placeholder}
          </option>
          {props?.options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <label dangerouslySetInnerHTML={{ __html: label }} />
        {help && <span className={styles.help}>{help}</span>}
        {error && <span className={styles.error}>{error}</span>}
      </div>
    );
  }

  if (type === 'textarea') {
    return (
      <div
        className={`${styles.inputField} ${styles.inputFieldTextarea} ${
          error && styles.inputFieldError
        } ${visibleLabel && styles.inputFieldVisibleLabel} ${className ?? ''}`}
      >
        <textarea
          name={name}
          required={required}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          ref={forwardRef as React.RefObject<HTMLTextAreaElement>}
          {...props}
        />
        <label dangerouslySetInnerHTML={{ __html: label }} />
        {help && <span className={styles.help}>{help}</span>}
        {error && <span className={styles.error}>{error}</span>}
      </div>
    );
  }

  return (
    <div
      className={`${styles.inputField} ${error && styles.inputFieldError} ${
        visibleLabel && styles.inputFieldVisibleLabel
      } ${className ?? ''}`}
    >
      <input
        name={name}
        required={required}
        type={type}
        // If disabled, use defaultValue instead of value
        {...(disabled ? { defaultValue: value } : { value })}
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChange}
        ref={forwardRef as React.RefObject<HTMLInputElement>}
        {...props}
      />
      <label dangerouslySetInnerHTML={{ __html: label }} />
      {help && <span className={styles.help}>{help}</span>}
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
}

export default InputField;
