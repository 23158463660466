import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import styles from './TimeTop.module.scss';
import Topbar from '../../topbar/Topbar';
import Button from '../../button/Button';
import { getWeekNumber } from '../../../utils/weekNumber';

function TimeTop({ mode = 'week' }: { mode?: 'week' | 'month' | 'year' }) {
  const { date = new Date().toISOString().split('T')[0] } = useParams() as {
    date: string;
  };
  const dateObj = new Date(date);

  return (
    <>
      <Topbar
        // Go to todays date when clicking the left button
        left={
          <Button
            buttonStyle="faded"
            size="tiny"
            href={`/time/log/${new Date().toISOString().split('T')[0]}`}
          >
            I dag
          </Button>
        }
        center={
          mode === 'week' ? (
            <>
              <Link
                // Go to previous week when clicking the previous button
                to={`/time/log/${
                  new Date(dateObj.getTime() - 7 * 24 * 60 * 60 * 1000)
                    .toISOString()
                    .split('T')[0]
                }`}
                className={styles.TimeTopCenterPreviousButton}
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 25L16.7071 20.7071C16.3166 20.3166 16.3166 19.6834 16.7071 19.2929L21 15"
                    stroke="#F5F7F3"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                </svg>
              </Link>
              <Link
                // Go to the current month when clicking the current button
                to={`/time/month/${date}`}
                className={styles.TimeTopCenterCurrent}
              >
                <h5 className={styles.TimeTopCenterCurrentTitle}>
                  Uge {getWeekNumber(dateObj)}
                </h5>
                {
                  // If the date is within the curent week, show the text "Denne uge"
                  getWeekNumber(new Date()) === getWeekNumber(dateObj) ? (
                    <span className={styles.TimeTopCenterCurrentText}>
                      Denne uge
                    </span>
                  ) : // If the date is within the next week, show the text "Næste uge"
                  getWeekNumber(new Date()) + 1 === getWeekNumber(dateObj) ? (
                    <span className={styles.TimeTopCenterCurrentText}>
                      Næste uge
                    </span>
                  ) : // If the date is within the previous week, show the text "Sidste uge"
                  getWeekNumber(new Date()) - 1 === getWeekNumber(dateObj) ? (
                    <span className={styles.TimeTopCenterCurrentText}>
                      Sidste uge
                    </span>
                  ) : null
                }
              </Link>
              <Link
                // Go to next week when clicking the next button
                to={`/time/log/${
                  new Date(dateObj.getTime() + 7 * 24 * 60 * 60 * 1000)
                    .toISOString()
                    .split('T')[0]
                }`}
                className={styles.TimeTopCenterNextButton}
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 25L23.2929 20.7071C23.6834 20.3166 23.6834 19.6834 23.2929 19.2929L19 15"
                    stroke="#F5F7F3"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                </svg>
              </Link>
            </>
          ) : mode === 'month' ? (
            <>
              <Link
                // Go to previous month when clicking the previous button
                to={`/time/month/${
                  new Date(dateObj.getTime() - 30 * 24 * 60 * 60 * 1000)
                    .toISOString()
                    .split('T')[0]
                }`}
                className={styles.TimeTopCenterPreviousButton}
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 25L16.7071 20.7071C16.3166 20.3166 16.3166 19.6834 16.7071 19.2929L21 15"
                    stroke="#F5F7F3"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                </svg>
              </Link>

              <Link // Go to the current year when clicking the current button
                to={`/time/year/${date}`}
                className={styles.TimeTopCenterCurrent}
              >
                <h5 className={styles.TimeTopCenterCurrentTitle}>
                  {dateObj.toLocaleString('da-DK', { month: 'long' })}
                </h5>
                <span className={styles.TimeTopCenterCurrentText}>
                  {dateObj.getFullYear()}
                </span>
              </Link>
              <Link
                // Go to next month when clicking the next button
                to={`/time/month/${
                  new Date(dateObj.getTime() + 30 * 24 * 60 * 60 * 1000)
                    .toISOString()
                    .split('T')[0]
                }`}
                className={styles.TimeTopCenterNextButton}
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 25L23.2929 20.7071C23.6834 20.3166 23.6834 19.6834 23.2929 19.2929L19 15"
                    stroke="#F5F7F3"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                </svg>
              </Link>
            </>
          ) : mode === 'year' ? (
            <>
              <Link
                // Go to previous year when clicking the previous button
                to={`/time/year/${
                  new Date(dateObj.getTime() - 365 * 24 * 60 * 60 * 1000)
                    .toISOString()
                    .split('T')[0]
                }`}
                className={styles.TimeTopCenterPreviousButton}
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 25L16.7071 20.7071C16.3166 20.3166 16.3166 19.6834 16.7071 19.2929L21 15"
                    stroke="#F5F7F3"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                </svg>
              </Link>

              <div className={styles.TimeTopCenterCurrent}>
                <h5 className={styles.TimeTopCenterCurrentTitle}>
                  {dateObj.toLocaleString('da-DK', { year: 'numeric' })}
                </h5>
              </div>
              <Link
                // Go to next year when clicking the next button
                to={`/time/year/${
                  new Date(dateObj.getTime() + 365 * 24 * 60 * 60 * 1000)
                    .toISOString()
                    .split('T')[0]
                }`}
                className={styles.TimeTopCenterNextButton}
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 25L23.2929 20.7071C23.6834 20.3166 23.6834 19.6834 23.2929 19.2929L19 15"
                    stroke="#F5F7F3"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                </svg>
              </Link>
            </>
          ) : null
        }
      />
    </>
  );
}

export default TimeTop;
