import styles from './Avatar.module.scss';

function Avatar({
  firstName,
  lastName,
  profilePhotoUrl,
  size = 'medium',
}: {
  firstName?: string;
  lastName?: string;
  profilePhotoUrl?: string;
  size?: 'small' | 'medium' | 'large';
}) {
  return (
    <div
      className={`${styles.Avatar} ${size && styles[size]}`}
      style={{ backgroundImage: `url(${profilePhotoUrl})` }}
    >
      {profilePhotoUrl ? null : `${firstName?.charAt(0)}${lastName?.charAt(0)}`}
    </div>
  );
}

export default Avatar;
