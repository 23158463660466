import styles from './auth.module.scss';
import { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase-config';
import Button from '../../components/button/Button';
import InputField from '../../components/inputfield/InputField';
import Panel from '../../components/panel/Panel';
import NotAuthorisedLayout from '../../layouts/NotAuthorised';
import { Link } from 'react-router-dom';

function LoginView() {
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      // Sign in the user with email and password
      await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
    } catch (error) {
      // Handle different error cases and set appropriate error messages
      setEmailErrorMessage('');
      setPasswordErrorMessage('');
      setEmailErrorMessage('Fejl i email eller kodeord. Prøv igen.');
      setPasswordErrorMessage('Fejl i email eller kodeord. Prøv igen.');
    }
  };

  return (
    <NotAuthorisedLayout>
      <Panel>
        <h4>Log ind</h4>
        <form onSubmit={handleSubmit}>
          <InputField
            name="email"
            label="Email"
            type="email"
            value={loginEmail}
            placeholder="Skriv din email"
            onChange={(event) => {
              setLoginEmail(event.target.value);
            }}
            error={emailErrorMessage}
          />

          <InputField
            name="password"
            label="Kodeord"
            type="password"
            value={loginPassword}
            placeholder="Skriv dit kodeord"
            onChange={(event) => {
              setLoginPassword(event.target.value);
            }}
            error={passwordErrorMessage}
          />
          <Button wide type="submit">
            Log ind
          </Button>
        </form>
        <div className={styles.forgotPasswordLink}>
          <Link to="/forgot-password">Glemt kodeord?</Link>
        </div>
      </Panel>
    </NotAuthorisedLayout>
  );
}

export default LoginView;
