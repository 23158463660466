import { useContext } from 'react';
import Panel from '../../components/panel/Panel';
import NotAuthorisedLayout from '../../layouts/NotAuthorised';
import { AdminContext } from '../../contexts/AdminContext';

function NoAccessView() {
  const admin = useContext(AdminContext);

  return (
    <NotAuthorisedLayout>
      <Panel>
        <h4>Du har ikke adgang til app'en</h4>
        <p>
          Hvis du mener at du burde have adgang, kan du kontakte{' '}
          <strong>
            {admin.firstName} {admin.lastName}
          </strong>{' '}
          for at få hjælp.
        </p>
      </Panel>
    </NotAuthorisedLayout>
  );
}

export default NoAccessView;
