import { AnimatePresence } from 'framer-motion';
import TimeTop from '../../components/time/timetop/TimeTop';
import DefaultLayout from '../../layouts/Default';
import { Outlet, useParams } from 'react-router-dom';
import TimeYearList from '../../components/time/year/TimeYearList';

function TimeYearView() {
  const { date } = useParams() as {
    date: string;
  };

  return (
    <DefaultLayout>
      <TimeTop mode="year" />
      <AnimatePresence>
        {date ? <Outlet key={date} /> : <TimeYearList />}
      </AnimatePresence>
    </DefaultLayout>
  );
}

export default TimeYearView;
