import { AnimatePresence, motion } from 'framer-motion';
import styles from './TimeYearList.module.scss';
import { Link, useParams } from 'react-router-dom';

function TimeYearList() {
  const { date = new Date().toISOString().split('T')[0] } = useParams() as {
    date: string;
  };

  // Determining the year and month from the parameter
  const currentYear = new Date(date).getFullYear();
  const monthsArray = Array.from({ length: 12 }, (_, i) => i);
  const MotionLink = motion(Link);

  return (
    <AnimatePresence>
      <motion.div
        className={styles.timeYearList}
        key={currentYear}
        variants={{
          visible: {
            transition: {
              delayChildren: 0.1,
              staggerChildren: 0.03,
            },
          },
          hidden: {
            transition: { staggerChildren: 0, staggerDirection: -1 },
          },
        }}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        {/* List out months of the year */}
        {monthsArray.map((month, i) => {
          const date = new Date(currentYear, month, 1, 12, 0, 0);
          const isCurrentMonth =
            month === new Date().getMonth() &&
            currentYear === new Date().getFullYear();
          const monthName = new Intl.DateTimeFormat('da-DK', {
            month: 'long',
          }).format(date);

          return (
            <MotionLink
              to={`/time/month/${date.toISOString().split('T')[0]}`}
              variants={{
                hidden: {
                  opacity: 0,
                  y: 10,
                },
                visible: {
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 0.1,
                  },
                },
              }}
              className={`${styles.timeYearListItem} ${
                isCurrentMonth ? styles.current : ''
              }
                `}
              key={i}
            >
              <h4>{monthName}</h4>
            </MotionLink>
          );
        })}
      </motion.div>
    </AnimatePresence>
  );
}

export default TimeYearList;
