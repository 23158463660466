// TimeWeekSummary.tsx

import styles from './TimeWeekSummary.module.scss';
import { useContext } from 'react';
import { UserContext } from '../../../contexts/UserContext';
import { TimeLogInterface } from '../../../types/Time';
import { currentTimeLogSetting } from '../../../utils/currentTimeLogSetting';
import DANISH_HOLIDAYS from '../../../constants/danishHolidays';
import TimeWeekSummaryDay from './TimeWeekSummaryDay';

function TimeWeekSummary({
  logs,
  startDate,
  endDate,
}: {
  logs: TimeLogInterface[];
  startDate: Date;
  endDate: Date;
}) {
  const { userInfo, company } = useContext(UserContext);

  // Step 1: Create an array of the days of the week based on the startDate and endDate
  const getDatesInRange = (start: Date, end: Date): Date[] => {
    const date = new Date(start.getTime());
    const dates: Date[] = [];

    while (date <= end) {
      const newDate = new Date(date);
      newDate.setHours(8, 0, 0, 0); // Ensure consistent time
      dates.push(newDate);
      date.setDate(date.getDate() + 1);
    }

    return dates;
  };

  const dates = getDatesInRange(startDate, endDate);

  // Step 2: Get the currentTimeLogSetting for each of the days
  const days = dates.map((date) => {
    const timeLogSetting = currentTimeLogSetting(company, userInfo, date);

    if (!timeLogSetting) {
      // User hasn't started yet on this day
      return {
        weekDay: date
          .toLocaleString('da-DK', {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
          })
          .replace(/(\d+)\./, 'd. $1')
          .replace(/^\w/, (c) => c.toUpperCase()),
        timeOff: 0,
        sickLeave: 0,
        work: 0,
        holiday: 0,
        overtime: 0,
        total: 0,
        notApplicable: true, // New flag
      };
    }

    let work = 0;
    let holiday = 0;
    let timeOff = 0;
    let sickLeave = 0;
    let overtime = 0;

    // Calculate normTime
    let normTime =
      (timeLogSetting.end.toMillis() - timeLogSetting.from.toMillis()) /
      1000 /
      60 /
      60;

    // Handle break duration
    if (timeLogSetting.breakDuration > 0) {
      let breakDuration = timeLogSetting.breakDuration / 60;
      if (timeLogSetting.paidBreak) {
        breakDuration = 0;
      }
      normTime -= breakDuration;
    }

    // Check if the day is a Danish holiday
    const isHoliday = DANISH_HOLIDAYS.some(
      (holiday) => holiday.value === date.toISOString().split('T')[0]
    );

    // If the user is not working or it is a holiday, set working hours to 0
    if (timeLogSetting.notWorking || isHoliday) {
      normTime = 0;
    }

    // Handle timelog entries
    const timeLog = logs.find(
      (log) => log.id === date.toISOString().split('T')[0]
    );

    timeLog?.data?.entries.forEach((entry) => {
      if (entry.type === 'sickLeave') {
        sickLeave += entry.hours;
      } else if (entry.type === 'holiday') {
        holiday += entry.hours;
      } else if (entry.type === 'timeOff') {
        timeOff += entry.hours;
      } else if (entry.type === 'overtime') {
        overtime += entry.hours;
      } else if (entry.type === 'work') {
        work += entry.hours;
      }
    });

    work += normTime - timeOff - sickLeave - holiday;

    return {
      weekDay: date
        .toLocaleString('da-DK', {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
        })
        .replace(/(\d+)\./, 'd. $1')
        .replace(/^\w/, (c) => c.toUpperCase()),
      timeOff,
      sickLeave,
      work,
      holiday,
      overtime,
      total: work + overtime,
      notApplicable: false, // Applicable day
    };
  });

  return (
    <div className={styles.timeWeekSummary}>
      {days.map((day, index) => (
        <TimeWeekSummaryDay
          key={index}
          weekDay={day.weekDay}
          timeOff={day.timeOff}
          sickLeave={day.sickLeave}
          work={day.work}
          holiday={day.holiday}
          overtime={day.overtime}
          total={day.total}
          notApplicable={day.notApplicable} // Pass the flag
        />
      ))}
    </div>
  );
}

export default TimeWeekSummary;
