import { createContext, useState } from 'react';
import Menu from '../components/menu/Menu';
import { AnimatePresence } from 'framer-motion';

type MenuContextType = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
};

export const MenuContext = createContext<MenuContextType>({
  isOpen: false,
  open: () => {},
  close: () => {},
});

export const MenuProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  return (
    <MenuContext.Provider value={{ isOpen, open, close }}>
      {children}
      <AnimatePresence>
        <Menu />
      </AnimatePresence>
    </MenuContext.Provider>
  );
};
