// TimeApproveView.tsx

import { AnimatePresence, motion } from 'framer-motion';
import DefaultLayout from '../../layouts/Default';
import Topbar from '../../components/topbar/Topbar';
import TimeApproveEntry from '../../components/time/approve/TimeApproveEntry';
import { useContext, useEffect, useState } from 'react';
import TimeApproveEmptyState from '../../components/time/approve/TimeApproveEmptyState';
import { UserContext } from '../../contexts/UserContext';
import { WeekEntry } from '../../types/Time';
import { currentWeek } from '../../utils/currentWeek';
import { isDateLocked } from '../../utils/isDateLocked';
import { getCompanyStartDate } from '../../utils/getCompanyStartDate';

function TimeApproveView() {
  const { userInfo, company } = useContext(UserContext);
  const [entries, setEntries] = useState<WeekEntry[]>([]);
  const currentDate = new Date();

  // Helper function to get the start date (Monday) of the week for a given date.
  const getStartOfWeek = (date: Date): Date => {
    const startOfWeek = new Date(date);
    startOfWeek.setHours(0, 0, 0, 0); // Normalize to midnight
    const day = startOfWeek.getDay();
    const diffToMonday = day === 0 ? -6 : 1 - day; // Adjust when Sunday (0) is encountered
    startOfWeek.setDate(startOfWeek.getDate() + diffToMonday);
    return startOfWeek;
  };

  // Helper function to get the end date (Sunday) of the week for a given start date.
  const getEndOfWeek = (startOfWeek: Date): Date => {
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999); // Normalize to end of day
    return endOfWeek;
  };

  // Helper function to construct the weekKey in the format "weekNumber-year".
  const constructWeekKey = (date: Date): string => {
    const weekNum = currentWeek(date);
    const weekYear = date.getFullYear();
    return `${weekNum}-${weekYear}`;
  };

  /**
   * Generates all open weeks for approval based on the user's startDate and company's startDate.
   * Excludes weeks that are already approved, weeks before the lock date, and the current week.
   *
   * @param startDate - The user's start date as a Date object.
   * @param endDate - The end date up to which to generate weeks.
   * @param approvedWeeks - An array of approved week keys.
   * @param companyStartDate - The company's start date as a Date object.
   * @returns An array of WeekEntry objects representing open weeks.
   */
  const generateOpenWeeks = (
    startDate: Date,
    endDate: Date,
    approvedWeeks: string[],
    companyStartDate?: Date
  ): WeekEntry[] => {
    const weeks: WeekEntry[] = [];
    let currentWeekStart = getStartOfWeek(startDate);

    // Iterate through each week from startDate to endDate
    while (currentWeekStart <= endDate) {
      const weekKey = constructWeekKey(currentWeekStart);
      const weekEnd = getEndOfWeek(currentWeekStart);

      // Determine if the date is locked based on multiple criteria
      const locked = isDateLocked(
        currentWeekStart,
        approvedWeeks,
        userInfo.startDate,
        companyStartDate
      );

      if (!locked) {
        weeks.push({
          weekNumber: currentWeek(currentWeekStart),
          startDate: new Date(currentWeekStart),
          endDate: new Date(weekEnd),
        });
      }

      // Move to the next week
      currentWeekStart.setDate(currentWeekStart.getDate() + 7);
    }

    return weeks;
  };

  /**
   * Fetches open weeks for the user based on their start date and company start date.
   * Excludes approved weeks and ensures that only weeks up to the last completed week are considered.
   */
  const fetchOpenWeeks = () => {
    if (!userInfo?.startDate) {
      setEntries([]);
      return;
    }

    // Convert Firebase Timestamp to Date
    const userStartDate = userInfo.startDate.toDate();
    const approvedWeeks = userInfo.timeLogApprovedWeeks || [];

    // Get the company's start date using the helper function
    const companyStartDate = getCompanyStartDate(company);

    // Calculate the start of the current week (Monday)
    const currentWeekStart = getStartOfWeek(currentDate);

    // Set the end date to the previous Sunday (end of last completed week)
    const endDateForGenerateOpenWeeks = new Date(currentWeekStart);
    endDateForGenerateOpenWeeks.setDate(
      endDateForGenerateOpenWeeks.getDate() - 1
    );
    endDateForGenerateOpenWeeks.setHours(23, 59, 59, 999); // Normalize to end of day

    // Determine the lock date as the later of userStartDate and companyStartDate
    let lockDate: Date | undefined;

    if (userInfo.startDate && companyStartDate) {
      lockDate =
        userStartDate > companyStartDate ? userStartDate : companyStartDate;
    } else if (companyStartDate) {
      lockDate = companyStartDate;
    } else if (userInfo.startDate) {
      lockDate = userStartDate;
    }

    // Generate open weeks based on the lock date
    const openWeeks = generateOpenWeeks(
      lockDate ? lockDate : userStartDate, // If lockDate is defined, use it as the startDate
      endDateForGenerateOpenWeeks,
      approvedWeeks,
      companyStartDate
    );

    setEntries(openWeeks);
  };

  useEffect(() => {
    fetchOpenWeeks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.startDate, userInfo.timeLogApprovedWeeks, company]);

  return (
    <DefaultLayout>
      <Topbar center={<h4>Godkend uger</h4>} />

      {entries.length > 0 ? (
        <AnimatePresence>
          <motion.div
            variants={{
              visible: {
                transition: {
                  delayChildren: 0.3,
                  staggerChildren: 0.1,
                },
              },
              hidden: {
                transition: { staggerChildren: 0, staggerDirection: -1 },
              },
            }}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            {entries.map((entry) => (
              <TimeApproveEntry
                key={`${
                  entry.weekNumber
                }-${entry.startDate.toISOString()}-${entry.endDate.toISOString()}`}
                weekNumber={entry.weekNumber}
                startDate={entry.startDate}
                endDate={entry.endDate}
              />
            ))}
          </motion.div>
        </AnimatePresence>
      ) : (
        <TimeApproveEmptyState />
      )}
    </DefaultLayout>
  );
}

export default TimeApproveView;
