import { useParams } from 'react-router-dom';
import styles from './TimeLogHeader.module.scss';
import { AnimatePresence, motion } from 'framer-motion';
import { currentTimeLogSetting } from '../../../utils/currentTimeLogSetting';
import { useContext } from 'react';
import { UserContext } from '../../../contexts/UserContext';
import { timeFormat } from '../../../utils/timeFormat';
import DANISH_HOLIDAYS from '../../../constants/danishHolidays';

function TimeLogHeader() {
  const { company, userInfo } = useContext(UserContext);
  const { date = new Date().toISOString().split('T')[0] } = useParams() as {
    date: string;
  };
  const dateObj = new Date(date);

  // Get the time log setting for the selected date
  const timeLogSetting = currentTimeLogSetting(company, userInfo, dateObj);

  // Check if the day is a Danish holiday
  const isHoliday = DANISH_HOLIDAYS.filter(
    (holiday) => holiday.value === date
  )[0];

  return (
    <div className={styles.timeLogHeader}>
      <AnimatePresence mode="wait">
        <motion.div
          className={styles.timeLogHeaderInner}
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 30 }}
        >
          <h4>
            {dateObj
              .toLocaleString('da-DK', {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
              })
              .replace(/(\d+)\./, 'd. $1')
              .replace(/^\w/, (c) => c.toUpperCase())}
          </h4>
          {timeLogSetting && !timeLogSetting.isPaidHourly && (
            <span className={styles.timeLogHeaderNormTime}>
              {isHoliday ? (
                isHoliday.label
              ) : timeLogSetting.notWorking ? (
                'Fri'
              ) : (
                <>
                  {timeFormat(timeLogSetting.from.toDate(), 'da-DK')} -{' '}
                  {timeFormat(timeLogSetting.end.toDate(), 'da-DK')}
                </>
              )}
            </span>
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
}

export default TimeLogHeader;
