import styles from './TimeLogInformationPanel.module.scss';
import { TimeLogWarning } from '../../../types/User';
import Infobox from '../../infobox/Infobox';
import Panel from '../../panel/Panel';

const mapWarningType = (type?: string) => {
  switch (type) {
    case '11HourWarning':
      return {
        title: 'Du overskrider 11-timers reglen',
        content: (
          <>
            <h4>11-timers reglen</h4>
            <p>
              11-timers reglen i Danmark kræver, at medarbejdere har mindst 11
              sammenhængende timers hvile mellem arbejdsperioder inden for hver
              24-timers periode.
            </p>
          </>
        ),
      };
    case '48HourWarning':
      return {
        title: 'Du overskrider 48-timers reglen',
        content: (
          <>
            <h4>48-timers reglen</h4>
            <p>
              48-timers reglen i Danmark kræver, at medarbejdere har et
              gennemsnit på under 48 timers arbejdstimer i en periode på 4
              måneder.
            </p>
          </>
        ),
      };
    default:
      return {
        title: 'Ukendt advarsel',
        content: <p>Der er sket en ukendt advarsel.</p>,
      };
  }
};

function TimeLogInformationPanel({
  info,
  warning,
  type,
}: {
  info?: {
    title: string;
    content: string;
  };
  type: 'warning' | 'info' | 'success' | 'danger';
  warning?: TimeLogWarning;
}) {
  if (!warning && !info) return null;

  if (info) {
    return (
      <div className={styles.timeLogInformationPanel}>
        <Panel size="small" type={type}>
          <div className={styles.timeLogInformationPanelInner}>
            <h5>{info.title}</h5>
            <p className={styles.timeLogInformationPanelText}>{info.content}</p>
          </div>
        </Panel>
      </div>
    );
  }

  const warningType = mapWarningType(warning?.type);

  return (
    <div className={styles.timeLogInformationPanel}>
      <Panel size="small" type="warning">
        <div className={styles.timeLogInformationPanelInner}>
          <h5>{warningType.title}</h5>
          <p className={styles.timeLogInformationPanelText}>
            Sidste registrering var kl.{' '}
            {`${warning?.fromDate.toDate().toLocaleTimeString('da-DK', {
              hour: '2-digit',
              minute: '2-digit',
            })}`}{' '}
            i går. <br />
            Dit starttidspunkt er kl.{' '}
            {warning?.toDate.toDate().toLocaleTimeString('da-DK', {
              hour: '2-digit',
              minute: '2-digit',
            })}{' '}
            i dag. <br />
            Der er kun {warning?.value} timer imellem.
          </p>
          <Infobox label="Hvad betyder det?" content={warningType.content} />
        </div>
      </Panel>
    </div>
  );
}

export default TimeLogInformationPanel;
