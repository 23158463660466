// utils/currentTimeLogSetting.ts

import { CompanyContextInterface } from '../types/Company';
import { TimeLogEntryType, weeklyScheduleItem } from '../types/Time';
import { TimeLogSetting, UserInfoContextInterface } from '../types/User';
import { Timestamp } from 'firebase/firestore';
import { getCompanyStartDate } from './getCompanyStartDate';

/**
 * Defines employment status and maps it to TimeLogEntryType.
 */
type EmploymentStatus = 'active' | 'inactive' | 'sickLeave' | 'leave' | 'other';

/**
 * Mapping from EmploymentStatus to TimeLogEntryType.
 */
const employmentStatusMap: Record<EmploymentStatus, TimeLogEntryType['type']> =
  {
    active: 'overtime',
    inactive: 'overtime',
    sickLeave: 'sickLeave',
    leave: 'timeOff',
    other: '',
  };

/**
 * Retrieves the TimeLogEntryType based on EmploymentStatus.
 *
 * @param status - The employment status.
 * @returns The corresponding TimeLogEntryType.
 */
const getEmploymentStatusType = (
  status: EmploymentStatus
): TimeLogEntryType['type'] => {
  if (!status) return 'overtime';
  return employmentStatusMap[status];
};

/**
 * Finds the most recent TimeLogSetting up to the given date.
 *
 * @param settings - Array of TimeLogSettings.
 * @param date - The date to compare.
 * @returns The most recent TimeLogSetting or null.
 */
const getMostRecentSetting = (
  settings: TimeLogSetting[] | undefined,
  date: Date
): TimeLogSetting | null => {
  if (!settings) return null;

  const targetDate = new Date(date.toDateString()).getTime();

  const filteredSettings = settings.filter((setting) => {
    const settingDate = new Date(setting.createdDate.seconds * 1000);
    const settingDateOnly = new Date(settingDate.toDateString()).getTime();
    return settingDateOnly <= targetDate;
  });

  if (filteredSettings.length === 0) {
    return null;
  }

  return filteredSettings.reduce((acc: TimeLogSetting | null, current) => {
    const currentDate = new Date(current.createdDate.seconds * 1000);
    const currentDateOnly = new Date(currentDate.toDateString()).getTime();
    const accDate = acc ? new Date(acc.createdDate.seconds * 1000) : null;
    const accDateOnly = accDate
      ? new Date(accDate.toDateString()).getTime()
      : null;

    if (
      !accDateOnly ||
      currentDateOnly > accDateOnly ||
      (currentDateOnly === accDateOnly &&
        settings.indexOf(current) > settings.indexOf(acc as TimeLogSetting))
    ) {
      return current;
    } else {
      return acc;
    }
  }, null);
};

/**
 * Retrieves the day settings from the weekly schedule based on the date.
 *
 * @param weeklySchedule - Array of weeklyScheduleItem.
 * @param date - The date to find the day settings for.
 * @returns The corresponding weeklyScheduleItem or null.
 */
const getDaySettings = (
  weeklySchedule: weeklyScheduleItem[] | undefined,
  date: Date
) => {
  if (!weeklySchedule) return null;
  const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'long' });
  return weeklySchedule.find((d) => d.day === dayOfWeek) || null;
};

/**
 * Retrieves the current TimeLogSetting based on user and company settings.
 *
 * @param company - The company context interface.
 * @param userInfo - The user info context interface.
 * @param date - The date for which to retrieve the setting.
 * @returns The current TimeLogSetting or null.
 */
export const currentTimeLogSetting = (
  company: CompanyContextInterface,
  userInfo: UserInfoContextInterface,
  date: Date
) => {
  if (userInfo?.settings?.timeRegistration?.exempt || !userInfo?.startDate) {
    return null;
  }

  // Get the company's start date
  const companyStartDate = getCompanyStartDate(company);

  // **Determine the lock date**
  let lockDate: Date | undefined;

  if (userInfo.startDate && companyStartDate) {
    const userStart = userInfo.startDate.toDate();
    lockDate = userStart > companyStartDate ? userStart : companyStartDate;
  } else if (companyStartDate) {
    lockDate = companyStartDate;
  } else if (userInfo.startDate) {
    lockDate = userInfo.startDate.toDate();
  }

  // **Special Rule:** Lock dates before August 5, 2024
  const specialLockedDate = new Date('2024-08-05');

  // **Effective Lock Date:** The later of lockDate and specialLockedDate
  let effectiveLockDate: Date | undefined;
  if (lockDate) {
    effectiveLockDate =
      lockDate > specialLockedDate ? lockDate : specialLockedDate;
  } else {
    effectiveLockDate = specialLockedDate;
  }

  // **Check if the date is locked**
  if (date < effectiveLockDate) {
    // The target date is before the effective lock date; do not allow time logging
    return null;
  }

  const defaultType = getEmploymentStatusType(userInfo.employmentStatus);
  const mostRecentCompanySetting = getMostRecentSetting(
    company?.timeLogSettings,
    date
  );

  let mostRecentSetting: TimeLogSetting | null = null;

  if (userInfo?.settings?.timeRegistration?.individualTimeLogSettings) {
    mostRecentSetting = getMostRecentSetting(userInfo?.timeLogSettings, date);
  }

  // If the user has no individual settings, use the company settings
  if (!mostRecentSetting) {
    mostRecentSetting = mostRecentCompanySetting;
  }

  // If the user has no company settings, return null
  if (!mostRecentSetting) {
    return null;
  }

  // If the user is paid hourly, there are no day settings and we return different values
  if (mostRecentSetting.isPaidHourly) {
    return {
      breakDuration: 0,
      paidBreak: false,
      from: Timestamp.now(),
      end: Timestamp.now(),
      notWorking: false,
      isPaidHourly: mostRecentSetting.isPaidHourly || false,
      defaultType: 'work' as TimeLogEntryType['type'],
      weeklySchedule: [],
    };
  }

  const daySettings = getDaySettings(mostRecentSetting?.weeklySchedule, date);

  if (!daySettings) return null;

  return {
    breakDuration: mostRecentSetting.breakDuration,
    paidBreak: mostRecentSetting.paidBreak,
    from: daySettings.from,
    end: daySettings.end,
    notWorking: daySettings.notWorking,
    isPaidHourly: mostRecentSetting.isPaidHourly || false,
    defaultType,
    weeklySchedule: mostRecentSetting.weeklySchedule,
  };
};
