import styles from './auth.module.scss';
import { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase-config';
import Button from '../../components/button/Button';
import InputField from '../../components/inputfield/InputField';
import Panel from '../../components/panel/Panel';
import NotAuthorisedLayout from '../../layouts/NotAuthorised';
import { Link } from 'react-router-dom';

function ForgotPasswordView() {
  const [resetEmail, setResetEmail] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [attemptedReset, setAttemptedReset] = useState(false);

  // Function to send a reset password email
  const sendResetPasswordEmail = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    try {
      // Sending reset password email using Firebase authentication
      await sendPasswordResetEmail(auth, resetEmail).then(() => {
        setAttemptedReset(true);
      });
    } catch (error: any) {
      setEmailErrorMessage('');
      // Handling different error cases and setting appropriate error messages
      if (error.message.includes('invalid-email')) {
        setEmailErrorMessage('Din email er ugyldig. Prøv at indtaste en anden');
      } else if (error.message.includes('user-not-found')) {
        setEmailErrorMessage('Kan ikke finde en bruger med denne email');
      } else {
        setEmailErrorMessage(error.message);
      }
    }
  };

  return (
    <NotAuthorisedLayout>
      <Panel>
        <h4>Nulstil kodeord</h4>
        {!attemptedReset ? (
          <form
            className="centered-layout__form"
            onSubmit={sendResetPasswordEmail}
          >
            <p>
              Vi sender dig en mail med link, hvor du kan klikke og nulstille
              dit kodeord.
            </p>
            <InputField
              name="email"
              label="Skriv din email"
              value={resetEmail}
              type="email"
              placeholder="Skriv din email"
              onChange={(event) => {
                setResetEmail(event.target.value);
              }}
              error={emailErrorMessage}
            />
            <Button wide type="submit">
              Nulstil kodeord
            </Button>
          </form>
        ) : (
          <>
            <p>Tjek din email og følg linket for at nulstille dit password.</p>
          </>
        )}
        <div className={styles.forgotPasswordLink}>
          <Link to="/">Tilbage til log ind</Link>
        </div>
      </Panel>
    </NotAuthorisedLayout>
  );
}

export default ForgotPasswordView;
