import styles from './Tabs.module.scss';

function Tabs({
  activeTab,
  setActiveTab,
  tabs,
}: {
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  tabs: { value: string; label: string }[];
}) {
  return (
    <div className={styles.Tabs}>
      {tabs.map((tab, index) => (
        <div
          className={`${styles.Tab} ${
            activeTab === tab.value ? styles.Active : ''
          }`}
          key={index}
          onClick={() => setActiveTab(tab.value)}
        >
          {tab.label}
        </div>
      ))}
    </div>
  );
}

export default Tabs;
