import styles from './Panel.module.scss';

function Panel({
  children,
  size,
  type,
}: {
  children: React.ReactNode;
  size?: string;
  type?: string;
}) {
  return (
    <div
      className={`${styles.panel} ${size && styles[`panel--size-${size}`]} ${
        type && styles[`panel--type-${type}`]
      }`}
    >
      {children}
    </div>
  );
}

export default Panel;
